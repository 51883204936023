@import 'reboot.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@font-face {
    font-family: 'Helvetica Now Text SA';
    src: url(../fonts/HNforTELUSSA.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text SA';
    src: url(../fonts/HNforTELUSSA-It.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text SA';
    src: url(../fonts/HNforTELUSSA-Bd.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text SA';
    src: url(../fonts/HNforTELUSSA-BdIt.ttf) format('ttruetypetf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text SA';
    src: url(../fonts/HNforTELUSSA-Md.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text SA';
    src: url(../fonts/HNforTELUSSA-MdIt.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

:root {
    --secondary: #0071C7;
    --grey: #797676;
    --black: #000;
    --dotsOffset: 26px;
    --white: #ffffff;
}

html, body, #root {
  height: 100%;
}

button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}
*:focus {
    outline: none;
}
[tabindex="0"] {
    cursor: pointer;
}

input[type="button" i],
input[type="submit" i],
input[type="reset" i],
input[type="file" i]::-webkit-file-upload-button,
button {
    text-align: inherit;
}

/* carousel custom styles */
.custom-carousel,
.custom-carousel .slick-list,
.custom-carousel .slick-track,
.custom-carousel .slick-slide > div {
    height: 100%;
    margin-top: 4px;
}

.dates-swiper .slick-list {
    width: 100%;
}

.custom-carousel .slick-track {
    margin: 0;
}

.no-margin .slick-track {
    margin: 0;
}

.center .slick-slide {
    transform: scale(0.7);
    opacity: 0.7;
    transition: all .3s ease-in-out;
}
.center .slick-slide.slick-active {
    transform: scale(1);
    opacity: 1;
}

.modal-body, .modal-body-with-scrollbar {
    overflow-y: auto;
    overflow-x: hidden
}

.modal-body::-webkit-scrollbar {
    display: none;
}

.slick-dots {
    padding-bottom: 4px !important;
}

.slick-dots li {
    margin: 0 !important;
    width: 14px !important;
}

.slick-dots li button:before{
    font-size: 8px !important;
    color: var(--black) !important;
    opacity: .15 !important;
    width: 14px !important;
    line-height: 15px !important;
}

.slick-dots li.slick-active button:before{
    color: var(--secondary) !important;
    opacity: 1 !important;
}

.slick-dots-white li button:before{
    font-size: 8px !important;
    color: var(--white) !important;
    opacity: .5 !important;
    width: 14px !important;
}

.slick-dots-white li.slick-active button:before{
    color: var(--white) !important;
    opacity: 1 !important;
}

.slick-dots li button {
    width: 14px !important;
    height: 14px !important;
}

.slick-dots li button:focus-visible{
    outline-color: var(--secondary) !important;
    outline-style: solid;
    outline-width: 2px;
    border-radius: 14px;
}

.slick-arrow {
    z-index: 1
}

.meMenuItem{
    height: 44px;
    display: inline-block;
}

.disclaimer-text a {
    color: blue;
}

.react-joyride__spotlight{
    background-color: transparent !important;
}

.goals .slick-track {
    margin: 0;
}

.explore .slick-track {
    margin: 0;
}

.communities .slick-track {
    margin: 0;
}

.reactCalendar .react-calendar__month-view__weekdays__weekday {
    font-family: 'Helvetica Now Text SA', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-top: 4px;
    padding-bottom: 10px;
    text-transform: capitalize;
}

.reactCalendar .react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none;
    cursor: auto;
}

.reactCalendar .react-calendar__tile--now > abbr {
    background-color: var(--secondary);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px;
    padding: 2px 8px 0;
}

.react-loading-skeleton {
    display: block !important;
    border-radius: 8px !important
}

#dashboard-skeleton .react-loading-skeleton {
    border-radius: 16px !important;
}

#dashboard-skeleton {
    width: 100%;
    margin-right: 30px;
}

#carousel-card-skeleton .react-loading-skeleton {
    border-radius: 4px !important;
}

#card-circle-skeleton .react-loading-skeleton {
    border-radius: 50% !important;
}

.lazyload-wrapper {
    max-width: 100%;
    height: 100%;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .sticky.sticky-fixed.is-sticky {
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
         backface-visibility: hidden;
  }

  .sticky.sticky-fixed.is-sticky:not([style*="margin-top"]) {
    margin-top: 0 !important;
  }
  .sticky.sticky-fixed.is-sticky:not([style*="margin-bottom"]) {
    margin-bottom: 0 !important;
  }

  .sticky.sticky-fixed.is-absolute{
    position: absolute;
  }

  .MuiIconButton-root.MuiAccordionSummary-expandIcon {
      padding: 0;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root {
      height: 50px;
  }

  .MuiIconButton-edgeEnd {
      margin-right: -10px !important;
  }

.apexcharts-tooltip {
   transition: none !important;
}
